import React, { useEffect, useState } from 'react'
import HeaderBar from '../../components/header-bar'
import { BackBtnTitle } from '../components/back-btn-title'
import { useParams } from 'react-router-dom';
import { ChallengeService } from '../service/challenge.service';
import { UserService } from '../service/user.service';
import { Loading } from '../components/loading';
import DisplayPicture from '../../components/ui/display-picture';
import { User } from '../model/dto/res/user';
import { connect } from 'react-redux';
import { ChallengeInfo as ChallengeInfoDTO } from '../model/dto/res/challenge-info';

interface Props {
    user: User;
}

const ChallengeInfo = (props: Props) => {
    let { id } = useParams<{ id: string }>();
    const [challengeData, setChallengeData] = useState<ChallengeInfoDTO>(null);
    const [challengerData, setChallengerData] = useState<User>(null);
    const [challengeToData, setChallengeToData] = useState(null);
    const [gameData, setGameData] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        loadChallenge();
    }, []);

    const loadChallenge = async () => {
        const _challengeData = await ChallengeService.getChallenge(id);
        const _challengerData = await UserService.getUserData(_challengeData.from);
        const _challengedTo = await UserService.getUserData(_challengeData.to);
        setChallengeData(_challengeData);
        setChallengerData(_challengerData);
        setChallengeToData(_challengedTo);
        setIsLoaded(true);

        (window as any).gtag('event', 'ViewChanllenge', {
            game: challengeData.game_info[0]?.name
        });
    }

    return (
        <div className='container'>
            <BackBtnTitle text='Challenge' path='/challenges'></BackBtnTitle>
            {!isLoaded && <Loading />}
            <div className='col-md-6 mx-auto rounded-3 position-relative py-5'>
                {/* <img src={Resource.WIN_EFFECT_GIF} alt="" className='position-absolute' /> */}
                <div className='shadow px-lg-5 px-3 d-flex justify-content-between my-2'>
                    {
                        challengerData &&
                        <div className='d-flex align-items-center flex-column w-100 py-4 anim-left-to-right'>
                            <DisplayPicture width={75} height={75} first_name={challengerData?.name || ''} last_name='' profile_pic={challengerData?.profile_img}></DisplayPicture>
                            <h5 className='mt-3 text-center'>{challengerData?.name}</h5>
                            <h4>{challengeData.score || 0}</h4>
                        </div>
                    }
                    <div className='d-flex align-items-center fw-bold mx-5 '><span className='fs-3 '>V</span><span className='fs-4 '>s</span></div>
                    {
                        challengeToData &&
                        <div className='d-flex align-items-center flex-column w-100 py-4 anim-right-to-left'>
                            <DisplayPicture width={75} height={75} first_name={challengeToData.name || ''} last_name='' profile_pic={challengeToData.profile_img}></DisplayPicture>
                            <h5 className='mt-3 text-center'>{challengeToData.name}</h5>
                            <h4>{challengeData?.score_beat || 0}</h4>
                        </div>
                    }
                </div>
                {challengeData?.status !== 1 && <div className='text-center fw-bold f-18 pb-3 px-5'>Challenge not played</div>}
                {challengeData?.winner && <>
                    {(props.user._id === challengeData?.winner) && <div className='text-center fw-bold f-18 pb-3 px-5'>Congratulations! You won the challenge. you earned the bet amount <b className='text-info f-25'>{challengeData?.bet_amount}</b> coins.</div>}
                    {(props.user._id !== challengeData?.winner) && <div className='text-center fw-bold f-18 pb-3 px-5'>You lost the game.</div>}
                </>}
                {challengeData?.status === 1 && !challengeData.winner && <>
                    <div className='text-center fw-bold f-18 pb-3 px-5'>Scores are level.<br/>Challenge failed</div>
                </>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.UserStore.user
});

const mapDispatchToProps = (dispatch: any) => ({
    // clearMultiplayer: () => dispatch({ type: PlatformActionType.CLEAR_MULTIPLAYER })
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeInfo);