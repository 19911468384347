import React from 'react'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { PlatformScreen } from '../redux/reducers/types';

interface Props {
    active_screen: PlatformScreen;
}

const Footer = (props: Props) => {
    return (
        <>
            {(props.active_screen !== PlatformScreen.HOME && props.active_screen !== PlatformScreen.IN_GAME) && <div className='bg-dark py-5 text-white text-center footer'>
                <div className="mt-5"></div>
                <div className="d-flex justify-content-center mb-2">
                    <NavLink to={'/terms'}>Terms & Conditions</NavLink>
                    <span className="mx-3">|</span>
                    <NavLink to={'/privacy'}>Privacy Policy</NavLink>
                    <span className="mx-3">|</span>
                    <NavLink to={'/contact'}>Contact Us</NavLink>
                    <span className="mx-3">|</span>
                    <NavLink to={'/about'}>About Us</NavLink>
                </div>
                <p className=''>
                    #722, Manjeera Majestic Homes, JNTU - Hitech City Road, Kukatpally -
                    Hyderabad, Telangana 500085
                </p>
                <p>
                    Copyright &copy; All rights reserved
                </p>
                <div style={{ paddingBottom: 100 }}></div>
            </div>}
        </>
    )
}
const mapStateToProps = (state: any) => ({
    active_screen: state.PlatformStore.active_screen,
});
export default connect(mapStateToProps)(Footer);