import React from 'react'
import { BackBtnTitle } from '../components/back-btn-title'

export const AboutUs = () => {
    return (
        <div className={`container px-3  position-relative`} >
            <BackBtnTitle text='ABout Us' path='/'></BackBtnTitle>
            <div className='row'>
                <div className='col-md-12 p-4'>
                    <div className="px-4">
                        <p>Moplay.in is where innovation meets gaming. As a dynamic game studio based out of Hyderabad India, we specialize in crafting engaging mobile-connected games that stand out in a competitive market.</p>
                        <p>With a talented team, we’ve successfully launched over 30 HTML5 games, blending creativity with quality.</p>
                        <p>Our library features a mix of familiar gameplay and unique experiences that have captivated a growing community of players. Moplay.in brings all our games together in one place, offering a seamless way to discover and enjoy them.</p>
                        <p>Play for free with ads or elevate your experience with our premium options, available through flexible subscription plans or one-time licensing—because gaming should be as accessible as it is enjoyable.</p>
                    </div>
                </div>
            </div>
        </div >
    )
}
