import React, { useState, MouseEvent, useRef, useEffect } from 'react';
import FormBuilder from '../components/form-builder';
import { FormField, FormValidators } from '../components/form-builder/model/form-field';
import { NavLink, useNavigate } from 'react-router-dom';
import { LOGIN, SIGNIN } from "../app/service/login.service";
import { PopupType } from '../app/redux/reducers/types';
import { connect } from 'react-redux';
import { Resources } from '../app/utility/resources';

interface Props {
  // userloginDetails?: any;
  // userLoginStatus?: any;
  hideLoginPopup?: () => void;
  showLoginPopup?: () => void;
}

const SignupPage: React.FC = (props: Props) => {
  const [parentsData, setParentsData] = useState<any>({});
  const [addressError, setAddressOneError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const navigation = useNavigate();
  const [userFlowAction, setuserFlowAction] = useState(0);
  const stopPropagation = (event: MouseEvent) => { event.stopPropagation(); }
  const [resValidationError, setResValidationError] = useState('');
  const [validationError, setValidationError] = useState('');
  const [loginStatus, setLoginStatus] = useState<boolean>(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isUserFormSubmitted, setIsUserFormSubmitted] = useState(false);
  const [currentParentsData, setCurrentParentsData] = useState<any>({});
  const formInputs = useRef<any>({});
  const currentYear = new Date().getFullYear();

  const handleInput = (data: any) => {
    setParentsData(data);
  };

  useEffect(() => {
    (window as any).gtag('event', 'SignUpOpen');

    if (props.hideLoginPopup) {
      props.hideLoginPopup();
    }
  }, []);

  const UserLogin = () => {
    navigation('/home');
    setTimeout(() => {
      if (props.showLoginPopup) {
        props.showLoginPopup();
      }
    }, 100);
  }

  const formValidations = [
    new FormField('gender', [FormValidators.REQUIRED]),
  ];

  const handleSubmit = () => {
    setIsFormSubmitted(true);
    const data = { ...parentsData.value };
    if (parentsData.isValid) {
      LOGIN(data).then((res: any) => {
        navigation('/home');
        (window as any).gtag('event', 'SignUpSuccess');
      }).catch((err: any) => {
        setResValidationError('Already email exist please use another email');
      })
    } else {
    }
  }

  const handleGender = (e: any) => {
    const data = { ...parentsData.value };
    data.gender = e.target.value;
    if (currentParentsData) {
      currentParentsData.gender = e.target.value;
    }
  }

  const closeLoginPopup = (event: MouseEvent) => {
    setuserFlowAction(0);
    setValidationError('');
    setIsUserFormSubmitted(false);
  }

  const handleLoginInput = (data: any): void => {
    setParentsData(data);
  };

  const userFormSubmit = () => {
    setIsUserFormSubmitted(true);
    const data = { ...parentsData.value };
    if (parentsData.isValid) {
      setLoginLoading(true)
      SIGNIN(data).then((res: any) => {
        if (res._id != null) {
          navigation('/home');
          setuserFlowAction(0);
          setLoginLoading(false)
          const data: any = loginStatus
            ;
        }
        else {
          return
        }
        setLoginLoading(false)
        const statusData: any = false;
      })
        .catch((err: any) => {
          setLoginLoading(false)
          console.error(err);
          setValidationError('Enter Valid Email Id or Password');
        })
    }
  }

  return (
    <div className="d-flex mt-5" onClick={closeLoginPopup}>
      <div className=" position-relative bg-white" onClick={stopPropagation} style={{margin: 'auto'}}>
        <div className="popup-heading">
          <NavLink to={'/home'} className="close-btn">
            <img src={Resources.POPUP_CLOSE_ICON} alt="" />
          </NavLink>
          <h4 className='position-relative text-center my-4' style={{ fontWeight: "bold" }}>Create Account</h4>
        </div>
        <div className="d-flex px-md-5 mx-auto flex-column p-3 rounded-3 border">
          <FormBuilder onUpdate={handleInput} showValidations={isFormSubmitted}>
            <form action="" ref={formInputs}>
              <div className="">
                <div className="mb-3">
                  <label htmlFor="login_username">Name</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input type="text" name="name" id="login_username" className="form-control" defaultValue={currentParentsData.name} placeholder="Name" data-validate-required="Please enter name" data-validate-name="name" />
                </div>
                <div className=" mb-3">
                  <label className="form-label mb-0">Email ID</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control" type="text" name="email" defaultValue={currentParentsData.email} placeholder="Email id" data-validate-required="Please enter email" data-validate-email="email Id" />
                </div>
                <div className=" mb-3">
                  <label className="form-label mb-0">Mobile Number</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control" type="text" name="phone_number" maxLength={10} defaultValue={currentParentsData.phone_number} placeholder="Mobile Number" data-validate-required="Please enter phone number" data-validate-mobile-number="phone number" />
                </div>
                <div className="mb-3">
                  <label className="form-label mb-0">Password </label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control" type="password" name="Pwd_Hash" defaultValue={currentParentsData.Pwd_Hash} placeholder="Password" data-validate-required="Please enter password" data-validate-password="password" />
                </div>
                <div className="mb-3">
                  <label className="form-label mb-0">Gender</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div>
                      <input type="radio" className='pointer' name="gender" value="male" checked={currentParentsData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                      <span style={{ margin: '0px 5px' }}>Male</span>
                    </div>
                    <div>
                      <input type="radio" className='pointer ms-2' name="gender" value="female" checked={currentParentsData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                      <span style={{ margin: '0px 5px' }}>Female</span>
                    </div>
                    <div>
                      <input className="mb-0 pointer" type="radio" value="transgender" name="gender" checked={currentParentsData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                      <span style={{ paddingLeft: '5px' }}>Other</span>
                    </div>
                  </div>
                  {isFormSubmitted && !currentParentsData?.gender && <p className="text-danger gameUser_form_label">Please select a gender</p>}
                </div>
                <div className="mb-3">
                  <label className="form-label mb-0">Address</label>
                  <input className="form-control" type="text" placeholder="Enter Address" defaultValue={currentParentsData.address} name="address" data-validate-addresh="Address" />
                  {addressError && <p className="text-danger gameUser_form_label">{addressError}</p>}
                </div>
              </div>
            </form>
          </FormBuilder>
          {resValidationError && <p className="text-danger">{resValidationError}</p>}
          <div className="form-footer border-top border-primary py-3 text-center mt-4">
            <button type="submit" className='btn btn-primary w-75 fw-bold' onClick={handleSubmit}>
              {loading &&
                <div className="text-center spinner">
                  <div className="spinner-border spinner-border-sm login-spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              }
              {!loading && (
                <>Sign up</>
              )}
            </button>
          </div>
        </div>
        <div className="pt-2 px-3 pt-lg-2 pt-sm-2 mb-3 d-flex justify-content-center">
          <small className="form-check-label">
            Already Have An Account?
            <b onClick={UserLogin} className=''> <span >Log In</span></b>
          </small>
        </div>
        <div className="text-center p-1 mb-4">
          <small className="copyright ms-3 fw-bold" >Copyright © {currentYear} Moplay. All Rights Reserved.</small>
        </div>
      </div>
      {/* Register Page ends */}
    </div>
  );
};
// const mapStateToProps = (state: any) => ({
//   userdata: state.UserStore
// });
const mapDispatchToProps = (dispatch: any) => ({
  hideLoginPopup: () => dispatch({ data: PopupType.IDLE, type: 'popup' }),
  showLoginPopup: () => dispatch({ data: PopupType.LOGIN, type: 'popup' }),
})
export default connect(null, mapDispatchToProps)(SignupPage);
// export default SignupPage;